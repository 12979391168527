exports.components = {
  "component---src-modules-blog-templates-blog-home-template-tsx": () => import("./../../../src/modules/blog/templates/BlogHomeTemplate.tsx" /* webpackChunkName: "component---src-modules-blog-templates-blog-home-template-tsx" */),
  "component---src-modules-blog-templates-single-post-template-tsx": () => import("./../../../src/modules/blog/templates/SinglePostTemplate.tsx" /* webpackChunkName: "component---src-modules-blog-templates-single-post-template-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-book-demo-tsx": () => import("./../../../src/pages/book-demo.tsx" /* webpackChunkName: "component---src-pages-book-demo-tsx" */),
  "component---src-pages-buy-bitcoin-tsx": () => import("./../../../src/pages/buy-bitcoin.tsx" /* webpackChunkName: "component---src-pages-buy-bitcoin-tsx" */),
  "component---src-pages-cookies-tsx": () => import("./../../../src/pages/cookies.tsx" /* webpackChunkName: "component---src-pages-cookies-tsx" */),
  "component---src-pages-eu-buy-bitcoin-tsx": () => import("./../../../src/pages/eu/buy-bitcoin.tsx" /* webpackChunkName: "component---src-pages-eu-buy-bitcoin-tsx" */),
  "component---src-pages-eu-cookies-tsx": () => import("./../../../src/pages/eu/cookies.tsx" /* webpackChunkName: "component---src-pages-eu-cookies-tsx" */),
  "component---src-pages-eu-index-tsx": () => import("./../../../src/pages/eu/index.tsx" /* webpackChunkName: "component---src-pages-eu-index-tsx" */),
  "component---src-pages-eu-offramp-tsx": () => import("./../../../src/pages/eu/offramp.tsx" /* webpackChunkName: "component---src-pages-eu-offramp-tsx" */),
  "component---src-pages-eu-privacy-tsx": () => import("./../../../src/pages/eu/privacy.tsx" /* webpackChunkName: "component---src-pages-eu-privacy-tsx" */),
  "component---src-pages-eu-products-onramp-tsx": () => import("./../../../src/pages/eu/products/onramp.tsx" /* webpackChunkName: "component---src-pages-eu-products-onramp-tsx" */),
  "component---src-pages-eu-products-stablecoin-payout-tsx": () => import("./../../../src/pages/eu/products/stablecoin-payout.tsx" /* webpackChunkName: "component---src-pages-eu-products-stablecoin-payout-tsx" */),
  "component---src-pages-eu-products-stablecoin-settlement-tsx": () => import("./../../../src/pages/eu/products/stablecoin-settlement.tsx" /* webpackChunkName: "component---src-pages-eu-products-stablecoin-settlement-tsx" */),
  "component---src-pages-eu-security-tsx": () => import("./../../../src/pages/eu/security.tsx" /* webpackChunkName: "component---src-pages-eu-security-tsx" */),
  "component---src-pages-eu-team-tsx": () => import("./../../../src/pages/eu/team.tsx" /* webpackChunkName: "component---src-pages-eu-team-tsx" */),
  "component---src-pages-eu-terms-tsx": () => import("./../../../src/pages/eu/terms.tsx" /* webpackChunkName: "component---src-pages-eu-terms-tsx" */),
  "component---src-pages-get-app-tsx": () => import("./../../../src/pages/get-app.tsx" /* webpackChunkName: "component---src-pages-get-app-tsx" */),
  "component---src-pages-get-started-tsx": () => import("./../../../src/pages/get-started.tsx" /* webpackChunkName: "component---src-pages-get-started-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-offramp-tsx": () => import("./../../../src/pages/offramp.tsx" /* webpackChunkName: "component---src-pages-offramp-tsx" */),
  "component---src-pages-personal-tsx": () => import("./../../../src/pages/personal.tsx" /* webpackChunkName: "component---src-pages-personal-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-products-global-payout-tsx": () => import("./../../../src/pages/products/global-payout.tsx" /* webpackChunkName: "component---src-pages-products-global-payout-tsx" */),
  "component---src-pages-products-onramp-tsx": () => import("./../../../src/pages/products/onramp.tsx" /* webpackChunkName: "component---src-pages-products-onramp-tsx" */),
  "component---src-pages-products-stablecoin-settlement-tsx": () => import("./../../../src/pages/products/stablecoin-settlement.tsx" /* webpackChunkName: "component---src-pages-products-stablecoin-settlement-tsx" */),
  "component---src-pages-security-tsx": () => import("./../../../src/pages/security.tsx" /* webpackChunkName: "component---src-pages-security-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-uk-cookies-tsx": () => import("./../../../src/pages/uk/cookies.tsx" /* webpackChunkName: "component---src-pages-uk-cookies-tsx" */),
  "component---src-pages-uk-index-tsx": () => import("./../../../src/pages/uk/index.tsx" /* webpackChunkName: "component---src-pages-uk-index-tsx" */),
  "component---src-pages-uk-privacy-tsx": () => import("./../../../src/pages/uk/privacy.tsx" /* webpackChunkName: "component---src-pages-uk-privacy-tsx" */),
  "component---src-pages-uk-products-global-payout-tsx": () => import("./../../../src/pages/uk/products/global-payout.tsx" /* webpackChunkName: "component---src-pages-uk-products-global-payout-tsx" */),
  "component---src-pages-uk-products-onramp-tsx": () => import("./../../../src/pages/uk/products/onramp.tsx" /* webpackChunkName: "component---src-pages-uk-products-onramp-tsx" */),
  "component---src-pages-uk-products-stablecoin-settlement-tsx": () => import("./../../../src/pages/uk/products/stablecoin-settlement.tsx" /* webpackChunkName: "component---src-pages-uk-products-stablecoin-settlement-tsx" */),
  "component---src-pages-uk-risk-warning-tsx": () => import("./../../../src/pages/uk/risk-warning.tsx" /* webpackChunkName: "component---src-pages-uk-risk-warning-tsx" */),
  "component---src-pages-uk-terms-tsx": () => import("./../../../src/pages/uk/terms.tsx" /* webpackChunkName: "component---src-pages-uk-terms-tsx" */),
  "component---src-pages-unavailable-region-tsx": () => import("./../../../src/pages/unavailable-region.tsx" /* webpackChunkName: "component---src-pages-unavailable-region-tsx" */),
  "component---src-pages-us-cookies-tsx": () => import("./../../../src/pages/us/cookies.tsx" /* webpackChunkName: "component---src-pages-us-cookies-tsx" */),
  "component---src-pages-us-cryptoasset-tsx": () => import("./../../../src/pages/us/cryptoasset.tsx" /* webpackChunkName: "component---src-pages-us-cryptoasset-tsx" */),
  "component---src-pages-us-index-tsx": () => import("./../../../src/pages/us/index.tsx" /* webpackChunkName: "component---src-pages-us-index-tsx" */),
  "component---src-pages-us-offramp-tsx": () => import("./../../../src/pages/us/offramp.tsx" /* webpackChunkName: "component---src-pages-us-offramp-tsx" */),
  "component---src-pages-us-patriot-act-tsx": () => import("./../../../src/pages/us/patriot-act.tsx" /* webpackChunkName: "component---src-pages-us-patriot-act-tsx" */),
  "component---src-pages-us-privacy-tsx": () => import("./../../../src/pages/us/privacy.tsx" /* webpackChunkName: "component---src-pages-us-privacy-tsx" */),
  "component---src-pages-us-products-global-payout-tsx": () => import("./../../../src/pages/us/products/global-payout.tsx" /* webpackChunkName: "component---src-pages-us-products-global-payout-tsx" */),
  "component---src-pages-us-products-onramp-tsx": () => import("./../../../src/pages/us/products/onramp.tsx" /* webpackChunkName: "component---src-pages-us-products-onramp-tsx" */),
  "component---src-pages-us-products-stablecoin-settlement-tsx": () => import("./../../../src/pages/us/products/stablecoin-settlement.tsx" /* webpackChunkName: "component---src-pages-us-products-stablecoin-settlement-tsx" */),
  "component---src-pages-us-team-tsx": () => import("./../../../src/pages/us/team.tsx" /* webpackChunkName: "component---src-pages-us-team-tsx" */),
  "component---src-pages-us-terms-tsx": () => import("./../../../src/pages/us/terms.tsx" /* webpackChunkName: "component---src-pages-us-terms-tsx" */)
}

