import React from 'react';
import type { FormProps, IChangeEvent } from '@rjsf/core';
import { withTheme } from '@rjsf/core';
import { Theme as MuiTheme } from '@rjsf/mui';
import type { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { PrimaryButton } from '../buttons/PrimaryButton';
import { formatErrors, generateUiSchema, transformErrors } from '../utils/dynamicForm';

export type TpRJSFSchema = RJSFSchema;

type FormData = Record<string, unknown>;

type DynamicFormProps = Omit<FormProps, 'onSubmit' | 'validator'> & {
  fieldMappings?: Record<string, string>;
  isLoading: boolean;
  onSubmit: (state: { formData: FormData }) => void;
  validationErrors?: Record<string, unknown> | null;
};

const Form = withTheme(MuiTheme);

export function DynamicForm({
  fieldMappings,
  formData,
  isLoading,
  onSubmit,
  schema,
  validationErrors,
  ...rest
}: DynamicFormProps): React.ReactElement {
  const generatedUiSchema = generateUiSchema(schema);
  const extraErrors = formatErrors(validationErrors, schema, fieldMappings);

  return (
    <Form
      extraErrors={extraErrors}
      formData={formData}
      schema={schema}
      showErrorList={false}
      transformErrors={transformErrors}
      uiSchema={generatedUiSchema}
      validator={validator}
      onSubmit={(data: IChangeEvent): void => {
        if (!data.formData) {
          return;
        }
        onSubmit({ formData: data.formData });
      }}
      {...rest}
    >
      <PrimaryButton loading={isLoading} type="submit">
        Continue
      </PrimaryButton>
    </Form>
  );
}
